<template>
  <div class="home">
    <div class="banner">
      <!-- 轮播图 -->
      <a-carousel ref="carousel_index" autoplay :dots="true">
        <div class="image-container">
          <img src="@/assets/Home/index_l1.jpg" alt="" class="image"/>
        </div>
        <div class="image-container">
          <img src="@/assets/Home/index_l2.jpg" alt="" class="image"/>
        </div>
        <div class="image-container">
          <img src="@/assets/Home/index_l3.jpg" alt="" class="image"/>
        </div>
      </a-carousel>
      <div class="arrow_left">
        <img
          src="@/assets/Home/icon_left.png"
          @click="
            () => {
              this.$refs.carousel_index.prev();
            }
          "
        />
      </div>
      <div class="arrow_right">
        <img
          src="@/assets/Home/icon_right.png"
          @click="
            () => {
              this.$refs.carousel_index.next();
            }
          "
        />
      </div>
      <ul class="tabList">
        <li v-for="(item, index) in tabList" :key="index">
          <img :src="item.icon" alt="" />
          <div>
            <span>{{ item.title }}</span>
            <span>{{ item.desc }}</span>
          </div>
        </li>
      </ul>
    </div>

    <Provide />
    <Solution />
    <ITManpowerOutsoucing />
    <News />
    <About />
    <PracticeCertification />
    <Partner />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Provide from '@/views/Home/components/Provide.vue'
//import Solution from '@/views/Home/components/Solution.vue'
//import ITManpowerOutsoucing from '@/views/Home/components/ITManpowerOutsoucing.vue'
//import PracticeCertification from '@/views/Home/components/PracticeCertification.vue'
// import News from '@/views/Home/components/News.vue'
import About from '@/views/Home/components/About.vue'
// import Partner from '@/views/Home/components/Partner.vue'
export default {
  name: 'HomeView',
  components: {
    Provide,
    //Solution,
   // ITManpowerOutsoucing,
    //PracticeCertification,
    // News,
    About,
    // Partner
  },
  setup() {
    // const state = reactive({
    //   tabList: [
    //     { title: '服务面广', icon: require('@/assets/Home/icon_main_point1.png'), desc: '服务领域涵盖范围广，涉及制造业、金融业、建筑业、医疗业等' },
    //     { title: '技术全面', icon: require('@/assets/Home/icon_main_point2.png'), desc: '精通主流开发技术，专业技术人员超过80' },
    //     { title: '服务稳定', icon: require('@/assets/Home/icon_main_point3.png'), desc: '专业售后团队提供365天7*24小时不间断服务' },
    //     { title: '经验丰富', icon: require('@/assets/Home/icon_main_point4.png'), desc: '为企业提供最新最全面的it优化解决方案' },
    //     { title: '开源节流', icon: require('@/assets/Home/icon_main_point5.png'), desc: '减少您的IT开支，并获取及时全面的支持服务' },
    //   ]
    // })
    // return {
    //   ...toRefs(state)
    // }
  }
}
</script>

<style lang="less" scoped>
.home {
  padding-bottom: 85px;
  margin-top: 60px;
}
.banner {
  //轮播图
  position: relative;
  width: 100%;
  height: 400px;

  img {
    width: 100%;
    height: 500px; //轮播图高度调整
  }
  .arrow_left,
  .arrow_right {
    position: absolute;
    top: 60%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    border-radius: 50%;
    background: #000000;
    opacity: 0.1;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 0.5;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }

  .arrow_left {
    left: 60px;
  }

  .arrow_right {
    right: 60px;
  }

  .arrow_left:hover,
  .arrow_right:hover {
    cursor: pointer;
  }

  /deep/.ant-carousel .slick-slide img {
    display: inline-block;
  }
  .bannerInfo {
    position: absolute;
    top: 70px;
    left: 15%;
    h1 {
      font-size: 51px;
      font-weight: 500;
      line-height: 71px;
      letter-spacing: 9px;
      color: #fff;
    }
    p {
      font-size: 18px;
      color: #ffffff;
      line-height: 25px;
      opacity: 60%;
      text-align: left;
    }
    .button {
      width: 81px;
      height: 60px;
      border-radius: 9px;
      border: 1px solid #ffffff;
      color: #fff;
      font-size: 25px;
      line-height: 60px;
      text-align: center;
      letter-spacing: 4px;
      margin-top: 72px;
    }
    .button:hover {
      cursor: pointer;
    }
  }
}
.tabList {
  width: 80%;
  height: 165px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 10%;
  li {
    display: flex;
    justify-content: center;
    width: 20%;
    height: 165px;
    background-color: rgba(255, 209, 209, 0.14);
    border-radius: 17px 17px 0px 0px;
    margin-right: 8px;

    img {
      width: 40px;
      height: 40px;
      margin-top: 54px;
    }
    div {
      margin: 44px 0 0 13px;
      span {
        display: block;
      }
      span:nth-child(1) {
        width: 65px;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
      }
      span:nth-child(2) {
        width: 140px;
        font-size: 14px;
        color: #fff;
        text-align: left;
        margin-top: 10px;
      }
    }
  }
  li:hover {
    background-color: rgba(255, 209, 209, 0.3);
  }
}
@media screen and (max-width: 1366px) {
 .banner{
   height:227px;
 }
}

</style>
