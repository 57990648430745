import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/Home/index.vue";
import HomeViewH5 from "@/views/H5/Home/index.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/solutions",
    name: "解决方案",
    meta: {
      title: "解决方案",
    },
    redirect: "/more_solutions",
    component: () => import("@/views/Solutions/Index.vue"),
    children: [
      {
        path: "takeOutRider",
        name: "外卖骑手小程序",
        meta: {
          title: "外卖骑手小程序",
        },
        component: () => import("@/views/Solutions/TakeOutRider.vue"),
      },
      // {
      //   path: "pharmaceuticals",
      //   name: "生物制药领域MES",
      //   meta: {
      //     title: "生物制药领域MES",
      //   },
      //   component: () => import("@/views/Solutions/Pharmaceuticals.vue"),
      // },
      {
        path: "enterprise",
        name: "笔记本电脑",
        meta: {
          title: "笔记本电脑",
        },
        component: () => import("@/views/Solutions/Enterprise.vue"),
      },
      {
        path: "campusfeedback",
        name: "一体机",
        meta: {
          title: "一体机",
        },
        component: () => import("@/views/Solutions/CampusFeedback.vue"),
      },
      {
        path: "dataCenter",
        name: "手表",
        meta: {
          title: "手表",
        },
        component: () => import("@/views/Solutions/DataCenter.vue"),
      },
      {
        path: "propertyMag",
        name: "物业管理APP",
        meta: {
          title: "物业管理APP",
        },
        component: () => import("@/views/Solutions/PropertyMag.vue"),
      },
      {
        path: "eco_platform",
        name: "电商平台",
        meta: {
          title: "电商平台",
        },
        component: () => import("@/views/Solutions/ECO_Platform.vue"),
      },
      {
        path: "carNavigation",
        name: "汽车导航",
        meta: {
          title: "汽车导航",
        },
        component: () => import("@/views/Solutions/CarNavigation.vue"),
      },
      {
        path: "erp",
        name: "Erp项目",
        meta: {
          title: "Erp项目",
        },
        component: () => import("@/views/Solutions/Erp.vue"),
      },
      {
        path: "fastComProject",
        name: "快信通项目",
        meta: {
          title: "快信通项目",
        },
        component: () => import("@/views/Solutions/FastComProject.vue"),
      },
      {
        path: "serviceManSystem",
        name: "服务管理系统",
        meta: {
          title: "服务管理系统",
        },
        component: () => import("@/views/Solutions/ServiceManSystem.vue"),
      },
      {
        path: "userManSystem",
        name: "用户管理系统",
        meta: {
          title: "用户管理系统",
        },
        component: () => import("@/views/Solutions/UserManSystem.vue"),
      },
      {
        path: "/more_solutions",
        name: "解决方案",
        meta: {
          title: "解决方案",
        },
        component: () => import("@/views/Solutions/More_solution.vue"),
      },
    ],
  },
  {
    path: "/ITManpowerOutsourcing",
    name: "ITManpowerOutsourcing",
    component: () => import("@/views/ITManpowerOutsourcing.vue"),
  },
  // 新闻中心
  // {
  //   path: "/news",
  //   name: "新闻中心",
  //   redirect: "/news/article",
  //   component: () => import("@/views/news/index.vue"),
  //   children: [
  //     // 详情
  //     {
  //       path: "detail",
  //       name: "detail",
  //       component: () => import("@/views/news/components/detail/index.vue"),
  //     },
  //     // 文章列表
  //     {
  //       path: "article",
  //       name: "article",
  //       component: () => import("@/views/news/components/article/index.vue"),
  //     },
  //   ],
  // },
];
const routesH5 = [
  {
    path: "/",
    name: "home",
    component: HomeViewH5,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/H5/AboutView.vue"),
  },
  {
    path: "/solutions",
    name: "解决方案",
    meta: {
      title: "解决方案",
    },
    redirect: "/more_solutions",
    component: () => import("@/views/H5/Solutions/Index.vue"),
    children: [
      {
        path: "takeOutRider",
        name: "外卖骑手小程序",
        meta: {
          title: "外卖骑手小程序",
        },
        component: () => import("@/views/H5/Solutions/TakeOutRider.vue"),
      },
      {
        path: "pharmaceuticals",
        name: "生物制药领域MES",
        meta: {
          title: "生物制药领域MES",
        },
        component: () => import("@/views/H5/Solutions/Pharmaceuticals.vue"),
      },
      {
        path: "enterprise",
        name: "企业培训系统",
        meta: {
          title: "企业培训系统",
        },
        component: () => import("@/views/H5/Solutions/Enterprise.vue"),
      },
      {
        path: "campusfeedback",
        name: "教育教学督导与评价系统",
        meta: {
          title: "教育教学督导与评价系统",
        },
        component: () => import("@/views/H5/Solutions/CampusFeedback.vue"),
      },
      {
        path: "dataCenter",
        name: "手表",
        meta: {
          title: "手表",
        },
        component: () => import("@/views/H5/Solutions/DataCenter.vue"),
      },
      {
        path: "propertyMag",
        name: "物业管理APP",
        meta: {
          title: "物业管理APP",
        },
        component: () => import("@/views/H5/Solutions/PropertyMag.vue"),
      },
      {
        path: "eco_platform",
        name: "电商平台",
        meta: {
          title: "电商平台",
        },
        component: () => import("@/views/H5/Solutions/ECO_Platform.vue"),
      },
      {
        path: "carNavigation",
        name: "汽车导航",
        meta: {
          title: "汽车导航",
        },
        component: () => import("@/views/H5/Solutions/CarNavigation.vue"),
      },
      {
        path: "erp",
        name: "Erp项目",
        meta: {
          title: "Erp项目",
        },
        component: () => import("@/views/H5/Solutions/Erp.vue"),
      },
      {
        path: "fastComProject",
        name: "快信通项目",
        meta: {
          title: "快信通项目",
        },
        component: () => import("@/views/H5/Solutions/FastComProject.vue"),
      },
      {
        path: "serviceManSystem",
        name: "服务管理系统",
        meta: {
          title: "服务管理系统",
        },
        component: () => import("@/views/H5/Solutions/ServiceManSystem.vue"),
      },
      {
        path: "userManSystem",
        name: "用户管理系统",
        meta: {
          title: "用户管理系统",
        },
        component: () => import("@/views/H5/Solutions/UserManSystem.vue"),
      },
      {
        path: "/more_solutions",
        name: "解决方案",
        meta: {
          title: "解决方案",
        },
        component: () => import("@/views/H5/Solutions/More_solution.vue"),
      },
    ],
  },
  {
    path: "/provideDetail",
    name: "provideDetail",
    component: () => import("@/views/H5/Provide/ProvideDetail.vue"),
  },
  {
    path: "/ITManpowerOutsourcing",
    name: "ITManpowerOutsourcing",
    component: () => import("@/views/H5/IT/ITManpowerOutsourcing.vue"),
  },
  {
    path: "/ITDetail",
    name: "ITDetail",
    component: () => import("@/views/H5/IT/ITDetail.vue"),
  },
  // 新闻中心
  {
    path: "/news",
    name: "新闻中心",
    redirect: "/news/article",
    component: () => import("@/views/H5/news/index.vue"),
    children: [
      // 详情
      {
        path: "detail",
        name: "detail",
        component: () => import("@/views/H5/news/components/detail/index.vue"),
      },
      // 文章列表
      {
        path: "article",
        name: "article",
        component: () => import("@/views/H5/news/components/article/index.vue"),
      },
    ],
  },
];
const screenWidth = document.documentElement.clientWidth;
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: screenWidth && screenWidth > 960 ? routes : routesH5,
});

export default router;
